import React from 'react';
import NavItem from './NavItem';
const SecondTierNavigation = ({ items, lang, depth, mobile, close }) => {
  //const classes = depth % 2 == 0 || mobile ? 'secondnav topoffset': 'secondnav leftoffset';
  const classes = depth == 2 || mobile ? 'secondnav topoffset' : 'secondnav leftoffset';
  return (
    <div className={classes}>
      {items.map((item) => (
        <NavItem item={item} lang={lang} key={item['@id']} depth={depth} mobile={mobile} close={close} />
      ))}
    </div>
  );
};

export default SecondTierNavigation;
