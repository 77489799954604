import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { isInternalURL, toBackendLang, flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import SecondTierNavigation from './SecondTierNavigation';

const NavItem = ({ item, lang, depth, mobile, close }) => {
  const { settings } = config;
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    if (mobile && close && (!item.items || item.items.length == 0 || depth > 2)) {
      close();
    }
    setIsClicked(!isClicked);
  };

  if ((isInternalURL(item['@id']) || item['@id'] === '')) {
    return (
      <div
        key={item.url}
        className="nav-item"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <NavLink
          to={flattenToAppURL(item['@id']) === '' ? '/' : flattenToAppURL(item['@id'])}
          className={isClicked ? "item" : "item active"}
          activeClassName="active"
          exact={
            settings.isMultilingual
              ? flattenToAppURL(item['@id']) === `/${toBackendLang(lang)}`
              : flattenToAppURL(item['@id']) === ''
          }
          onClick={handleClick}
        >
          {item.title}
          {item.items && item.items.length > 0 && depth <= 2 &&
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M9.293 14.707L10.707 13.293 18 20.586 25.293 13.293 26.707 14.707 18 23.414z"
              />
            </svg>
          }

        </NavLink>
        {!mobile &&
          isHovered && item.items && item.items.length > 0 && depth <= 2 && <SecondTierNavigation items={item.items} depth={depth + 1} mobile={mobile} close={close} />
          //item.items && item.items.length > 0 && depth <= 2 && <SecondTierNavigation items={item.items} depth={depth + 1} mobile={mobile} close={close} />
        }
        {mobile &&
          isClicked && item.items && item.items.length > 0 && depth <= 2 && <SecondTierNavigation items={item.items} depth={depth + 1} mobile={mobile} close={close} />
          //item.items && item.items.length > 0 && depth <= 2 && <SecondTierNavigation items={item.items} depth={depth + 1} mobile={mobile} close={close} />
        }
        {/*isHovered && item.items && item.items.length > 0 && depth <=2 && <SecondTierNavigation items={item.items} depth={depth + 1} mobile={mobile} close={close} />*/}
      </div>
    );
  } else {
    return (
      <>
        <a
          href={item['@id']}
          key={item['@id']}
          className="item"
          rel="noopener noreferrer"
          target={settings.openExternalLinkInNewTab ? '_blank' : '_self'}
        >
          {item.title}
        </a>
        {isHovered && item.items && item.items.length > 0 && <SecondTierNavigation items={item.items} depth={depth + 1} />}
      </>
    );
  }
};

export default NavItem;
