import React, { useEffect, useState, useContext } from 'react';
import { Button } from 'semantic-ui-react';
import cx from 'classnames';
import { ConditionalLink } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';
import Cookies from 'js-cookie';
import * as CookieConsent from "vanilla-cookieconsent";
// import CookieContext from '../../../hochschul-frontpage/src/customizations/components/theme/CookieProvider';

const messages = defineMessages({
  ButtonText: {
    id: 'Button text',
    defaultMessage: 'Button text',
  },
});

const View = ({ data, isEditMode, className }) => {
  const [hasLink, setHasLink] = useState(false);
  const [isDonation, setIsDonation] = useState(false);
  const [isFunctionalEnabled, setIsFunctionalEnabled] = useState();

  const intl = useIntl();

  useEffect(() => {
    if (data.href) {
      if (data.href && data.href.length > 0) {
        setHasLink(true);
      }
      if (data.href.length === 0) {
        setHasLink(false);
      }
    }
    setIsDonation(data.spenden);
  }, [data.href]);



  useEffect(() => {
    const cookieValue = Cookies.get('cc_cookie');

    if (cookieValue) {
      try {
        const cookieData = JSON.parse(cookieValue);
        if (cookieData.categories && cookieData.categories.includes('functional')) {
          setIsFunctionalEnabled(true);
        } else {
          setIsFunctionalEnabled(false);
        }

      } catch (error) {
        console.error('Error parsing cc_cookie:', error);
      }
    }
  });


  let donationButton = (
    isFunctionalEnabled ? (
      <a
        id={"ef-bl-x7jn2nd9j"}
        href={"/spenden/?ef-page=1&spendenstiftung"}
      >
        <Button className={cx('button', data.align, { 'disabled': !isFunctionalEnabled })}>
          {data.title || intl.formatMessage(messages.ButtonText)}
        </Button>
      </a>
    ) : (
      <a className='disabled cookie-warning'>
        <div>
          <button className="cookie-button" type="button" data-cc="show-preferencesModal">
            {data.title || intl.formatMessage(messages.ButtonText)}

          </button>
        </div>
        <p>Bitte aktivieren Sie Cookies, um zu spenden.</p>
      </a>
    )

  );

  let link = isDonation ? (
    donationButton
  ) : (hasLink && !isEditMode) ? (
    data.href.length > 0 ? (
      <a
        id={data.id}
        href={data.href}
      >
        <Button className={cx('button', data.align)}>
          {data.title || intl.formatMessage(messages.ButtonText)}
        </Button>
      </a>
    ) : null
  ) : (
    <Button className="noLink">
      {data.title || intl.formatMessage(messages.ButtonText)}
    </Button>
  );


  return (
    <>
      <div className={cx('block __button ', className)}>
        <div className="button container button_block ">
          <div className={cx(`align ${data?.inneralign} ${data.big ? 'bigger' : ""} `)}>
            {link}
            {/* <div className={`${isFunctionalEnabled && "hidden"}`}>
              <p className="cookie-warning">
                Please enable cookies for the donation button to work.
              </p>
              <Button className={cx('button')} type="button" data-cc="show-preferencesModal">
                {"Cookies"}
              </Button>
            </div> */}

          </div>
        </div>
      </div>

    </>
  );
};

export default View;
