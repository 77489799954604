import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ButtonBlock: {
    id: 'Button Block',
    defaultMessage: 'Button',
  },
  ButtonTitle: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  ButtonLink: {
    id: 'Link',
    defaultMessage: 'Link',
  },
  Align: {
    id: 'Alignment',
    defaultMessage: 'Alignment',
  },
  innerAlign: {
    id: ' Inner Alignment',
    defaultMessage: 'Inner Alignment',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
  buttonsize: {
    id: "Button size",
    defaultMessage: 'Großer Button'
  },
  spenden: {
    id: "Spenden Button",
    defaultMessage: 'Spenden Button'
  }
});

export const ButtonSchema = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.ButtonBlock),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'spenden', 'big', 'openLinkInNewTab','href', 'id'],
      },
    ],

    properties: {
      title: {
        title: props.intl.formatMessage(messages.ButtonTitle),
      },
      spenden: {
        title: intl.formatMessage(messages.spenden),
        type: 'boolean',
      },
      href: {
        title: props.intl.formatMessage(messages.ButtonLink),
        type: 'string'
      },
      inneralign: {
        title: props.intl.formatMessage(messages.innerAlign),
        widget: 'inner_align',
        default: 'left',
      },
      openLinkInNewTab: {
        title: intl.formatMessage(messages.openLinkInNewTab),
        type: 'boolean',
      },
      id: {
        title: "Button ID",
        type: 'string'
      },
      big: {
        title: intl.formatMessage(messages.buttonsize),
        type: 'boolean',
      },
    },
    required: [],
  };
};
