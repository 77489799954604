import React, { useEffect, createContext, useState, useRef } from 'react';
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

export const CookieContext = createContext(null);

const CookieProvider = ({ children }) => {
    const [functionalEnabled, setIsFunctionalEnabled] = useState(undefined);
    const functionalEnabledRef = useRef(functionalEnabled);

    useEffect(() => {
        functionalEnabledRef.current = functionalEnabled;
    }, [functionalEnabled]);

    useEffect(() => {
        CookieConsent.run({
            categories: {
                necessary: {
                    enabled: true,
                    readOnly: true
                },
                functional: {
                    enabled: true,
                }
            },
            language: {
                default: 'de',
                translations: {
                    de: {
                        consentModal: {
                            title: "Wir verwenden Cookies 🍪",
                            description: "Unsere Cookies sind alle technisch notwendig, um Ihnen die beste Erfahrung auf unserer Website zu bieten.",
                            acceptAllBtn: "Alle akzeptieren",
                            showPreferencesBtn: "Weitere Informationen"
                        },
                        preferencesModal: {
                            title: "Cookie-Einstellungen verwalten",
                            acceptAllBtn: "Alle akzeptieren",
                            acceptNecessaryBtn: "Nur notwendige akzeptieren",
                            savePreferencesBtn: "Auswahl speichern",
                            closeIconLabel: "Schließen",
                            sections: [
                                {
                                    title: "Weitere Informationen",
                                    description: "Unsere Website verwendet keine Tracking-Cookies oder Werbeanzeigen. Es werden außerdem keinerlei Analysedaten gesammelt."
                                },
                                {
                                    title: "Technisch notwendige Cookies",
                                    description: "Diese Cookies sind unerlässlich für den Betrieb unserer Website. Sie ermöglichen grundlegende Funktionen wie die Speicherung Ihres Login-Tokens und die Sicherheit der Website.",
                                    linkedCategory: "necessary"
                                },
                                {
                                    title: 'Spenden Formular',
                                    description: 'Dieser Cookie wird für den Betrieb des Spendenformulars benötigt.',
                                    linkedCategory: 'functional'
                                }
                            ]
                        }
                    }
                }
            },
            onFirstConsent: ({ cookie }) => handleConsent(cookie, true),
            onConsent: ({ cookie }) => handleConsent(cookie),
            onChange: ({ cookie }) => handleConsent(cookie)
        });

        const handleConsent = (cookie, reload) => {
            const isFunctionalEnabled = cookie.categories.includes("functional");
            if ((functionalEnabledRef.current !== undefined && isFunctionalEnabled !== functionalEnabledRef.current) || reload) {
                window.location.reload();
            }
            setIsFunctionalEnabled(isFunctionalEnabled);
        };
    }, []);

    return (
        <></>
    );
};

export default CookieProvider;
