/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React, { useEffect, useState } from 'react';
import { Container, List, Segment } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getNavigation } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  UniversalLink,
  SearchWidget
} from '@plone/volto/components';
import lightIcon from './lightbulb.svg';
import telephoneIcon from './telephone.svg';


/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const { siteActions = [] } = useSelector(
    (state) => ({
      siteActions: state.actions?.actions?.site_actions,
    }),
    shallowEqual,
  );

  const cleanItems = (data) => {
    if (!data.items) {
      return {}
    }
    for (const item of data.items) {
      // console.log(item)
      if (flattenToAppURL(item['@id']) === "/news") {
        // console.log("news")
        // console.log(item.items)
        return item.items.reverse();
      }
    }
  }

  const dispatch = useDispatch();
  const [items, setItems] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const fetchedItems = await dispatch(getNavigation("/", 6));
      setItems(cleanItems(fetchedItems)); // Update the state with the fetched items

    };
    fetchData();
  }, [dispatch]);

  //   <span className="heart-icon" style={{ backgroundImage: `url(${heartIcon})` }}></span>

  return (
    <Segment
      role="contentinfo"
      vertical
      padded
      inverted
      color="grey"
      textAlign="center"
      id="footer"
      aria-label="Footer"
    >
      <div className="footer-container">
        <Segment basic inverted>
          <div className="ui twelve column grid column-grid">
            <div className="four wide computer twelve wide mobile six wide tablet column column-blocks-wrapper">
              <div className='icon-section'>
                <span className="light-icon" style={{ backgroundImage: `url(${lightIcon})` }}></span>
                <p>Ihre Spende bringt uns voran.</p>
              </div>
              <p className='hint'>Stiftung Hochschulmedizin Dresden</p>
              <p>
                Ostsächsische Sparkasse Dresden<br />
                IBAN DE27 8505 0300 0221 0457 40<br />
                BIC OSDDDE81XXX
              </p>
              <div className='icon-section spaced'>
                <span className="telephone-icon" style={{ backgroundImage: `url(${telephoneIcon})` }}></span>
                <p>Spendenrufnummer</p>
              </div>
              <a href="tel:+49 351 458-3715">
                +49 351 458-3715
              </a>
              <a href="mailto:info@stiftung-hochschulmedizin.de">
                info@stiftung-hochschulmedizin.de
              </a>
            </div>
            <div className="four wide computer twelve wide mobile six wide tablet column column-blocks-wrapper">
              <h4>
                NEUSTE BEITRÄGE
              </h4>
              <div className='footer-news'>
                {items && items.slice(0, 6).map((item, index) => (
                  <UniversalLink key={index} href={flattenToAppURL(item['@id'])} >
                    {item.title}
                  </UniversalLink>
                ))}
              </div>
            </div>

            <div className="four wide computer twelve wide mobile six wide tablet column column-blocks-wrapper">
              <h4>
                SEITE DURCHSUCHEN
              </h4>
              <SearchWidget />
              <UniversalLink href={'/impressum'}>
                Impressum
              </UniversalLink>
              <UniversalLink href={'/datenschutz'}>
                Datenschutz
              </UniversalLink>
              <UniversalLink href={'/login'}>
                Anmelden
              </UniversalLink>

              <button className='cookie-button' type="button" data-cc="show-preferencesModal">Cookie-Einstellungen 🍪</button>
            </div>
          </div>
        </Segment>
        <List horizontal inverted>
          <div role="listitem" className="item">
            {"Ⓒ Stiftung Hochschulmedizin Dresden"}
          </div>
          <div role="listitem" className="item">
            {"Fetscherstraße 74"}
          </div>
          <div role="listitem" className="item">
            {"01307 Dresden"}
          </div>
          <div role="listitem" className="item">
            {"+49 351 458-3715"}
          </div>
          <div role="listitem" className="item">
            <a href="mailto:info@stiftung-hochschulmedizin.de">
              info@stiftung-hochschulmedizin.de
            </a>
          </div>
        </List>
      </div>
    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
