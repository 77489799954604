import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { Menu } from 'semantic-ui-react';
import { BodyClass, getBaseUrl, hasApiExpander } from '@plone/volto/helpers';
import { getNavigation } from '@plone/volto/actions';
import NavItems from '@plone/volto/components/theme/Navigation/NavItems';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  SearchWidget,
} from '@plone/volto/components';
const messages = defineMessages({
  closeMobileMenu: {
    id: 'Close menu',
    defaultMessage: 'Close menu',
  },
  openMobileMenu: {
    id: 'Open menu',
    defaultMessage: 'Open menu',
  },
});

const Navigation = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { pathname, type } = props;
  const [isMobileMenuOpen, setisMobileMenuOpen] = useState(false);
  const token = useSelector((state) => state.userSession.token, shallowEqual);
  const items = useSelector((state) => state.navigation.items, shallowEqual);
  const [items2, setItems] = useState();
  const lang = useSelector((state) => state.intl.locale);

  const cleanItems = (items) => {
    const reduced = items.items.map((item) => {
      if (flattenToAppURL(item["@id"]) === "/news") {
        return { ...item, items: [] }
      }
      else
        return item;
    })
    return reduced.slice(1);
  };

  /*
    useEffect(() => {
      const { settings } = config;
      if (!hasApiExpander('navigation', getBaseUrl(pathname))) {
        dispatch(getNavigation(getBaseUrl(pathname), 5));
      }
    }, [pathname, token, dispatch]);
  */
  useEffect(() => {
    const fetchData = async () => {
      const fetchedItems = await dispatch(getNavigation(getBaseUrl(pathname), 5));
      setItems(cleanItems(fetchedItems)); // Update the state with the fetched items
    };

    fetchData();
  }, [pathname, token, dispatch]);


  const toggleMobileMenu = () => {
    setisMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    if (!isMobileMenuOpen) {
      return;
    }
    setisMobileMenuOpen(false);
  };



  return (
    <>

      <nav className="navigation" id="navigation" aria-label="Site">
        <Menu
          stackable
          pointing
          secondary
          onClick={closeMobileMenu}
          className='large screen widescreen only'
        >
          <NavItems items={items2} lang={lang} />

        </Menu>
        
      </nav>
      <div className="large screen widescreen only search-wrapper">
          <div className="search">
            <SearchWidget />
          </div>
        </div>

    </>
  );
};

Navigation.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default Navigation;
