/**
 * Video view component.
 * @module components/theme/View/VideoVIew
 */

import React from 'react';

/**
 * File view component class.
 * @function VideoView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

const VideoView = ({ content }) => {
    return (
        <>
            <video
                src={
                    content.file.download
                }
                controls
                type="video/mp4"
            />
        </>
    )
}
export default VideoView;
