import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { TextBlockView } from '@plone/volto-slate/blocks/Text';
import { DetachedTextBlockEditor } from '@plone/volto-slate/blocks/Text/DetachedTextBlockEditor';
import TextLineEdit from '@plone/volto/components/manage/TextLineEdit/TextLineEdit';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { Container as SemanticContainer, Button } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import config from '@plone/volto/registry';
import { Image } from 'semantic-ui-react';
import { ConditionalLink } from '@plone/volto/components';
import { Link } from "react-router-dom";

const HighlightView = (props) => {
    const {
        data,
        isEditMode,
    } = props;

    const customContainer = config.getComponent({ name: 'Container' }).component;
    const Container =
        config.getComponent({ name: 'Container' }).component || SemanticContainer;

    const [url, setUrl] = useState()
    useEffect(() => {
        if (data.link !== undefined) {
            setUrl(data.link);
        }
    }, [data]); // Only re-run the effect if `jsonData` changes

    return (
        <div className={`full-width ${data.color && data.color["--class"] && data.color["--class"]}`}>
            <div className="columns-view  ">
                <Container className="info-container ">
                    {data.align === "left" ? (
                        <>
                            <div className="ui twelve column grid column-grid">
                                <div className="six wide computer twelve wide mobile six wide tablet column column-blocks-wrapper">
                                </div>
                                <div className="six wide computer twelve wide mobile six wide tablet column column-blocks-wrapper">
                                    <Container
                                        className={cx('teaser-description-title', {
                                            padded: !customContainer,
                                        })}
                                    >
                                        <div className="title">
                                            {isEditMode ? (
                                                <TextLineEdit
                                                    {...props}
                                                    renderTag="h2"
                                                    renderClassName=""
                                                    fieldDataName="title"
                                                    properties={{ title: data.title }}
                                                />
                                            ) : (
                                                <>{data?.title && <h2>{data?.title}</h2>}</>
                                            )}
                                        </div>
                                    </Container>
                                </div>
                            </div>
                            <div className="ui twelve column grid column-grid">
                                <div className="six wide computer twelve wide mobile six wide tablet column column-blocks-wrapper">
                                    {data.image && (<Image src={data.image['@id'] + '/@@images/image/large'} alt={data.alt ? data.alt : null} />)}
                                </div>
                                <div className="six wide computer twelve wide mobile six wide tablet column column-blocks-wrapper">
                                    <Container
                                        className={cx('teaser-description-title', {
                                            padded: !customContainer,
                                        })}
                                    >
                                        <div className="description">
                                            {isEditMode ? (
                                                <DetachedTextBlockEditor {...props} />
                                            ) : (
                                                <TextBlockView {...props} />
                                            )}
                                        </div>
                                        {data?.showButton && (
                                            <ConditionalLink to={flattenToAppURL(url)} condition={!isEditMode}>
                                                <div className={`info-button-container ${data.color && data.color["--class"] ? data.color["--class"] : ''}`}>
                                                    <Button >
                                                        {data?.textButton}
                                                    </Button>
                                                </div>
                                            </ConditionalLink>
                                        )}
                                    </Container>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="ui twelve column grid column-grid left">
                                <div className="six wide computer twelve wide mobile six wide tablet column column-blocks-wrapper">
                                    <Container
                                        className={cx('teaser-description-title title-left', {
                                            padded: !customContainer,
                                        })}
                                    >
                                        <div className="title">
                                            {isEditMode ? (
                                                <TextLineEdit
                                                    {...props}
                                                    renderTag="h2"
                                                    renderClassName=""
                                                    fieldDataName="title"
                                                    properties={{ title: data.title }}
                                                />
                                            ) : (
                                                <>{data?.title && <h2>{data?.title}</h2>}</>
                                            )}
                                        </div>
                                    </Container>
                                </div>
                                <div className="six wide computer twelve wide mobile six wide tablet column column-blocks-wrapper">
                                </div>
                            </div>
                            <div className="ui twelve column grid column-grid left">
                                <div className="six wide computer twelve wide mobile six wide tablet column column-blocks-wrapper">
                                    <Container
                                        className={cx('teaser-description-title', {
                                            padded: !customContainer,
                                        })}
                                    >
                                        <div className="description">
                                            {isEditMode ? (
                                                <DetachedTextBlockEditor {...props} />
                                            ) : (
                                                <TextBlockView {...props} />
                                            )}
                                        </div>
                                        {data?.showButton && (
                                            <ConditionalLink to={flattenToAppURL(url)} condition={!isEditMode}>
                                            <div className={`info-button-container ${data.color && data.color["--class"] ? data.color["--class"] : ''}`}>
                                                <Button >
                                                    {data?.textButton}
                                                </Button>
                                            </div>
                                        </ConditionalLink>
                                        )}
                                    </Container>
                                </div>
                                <div className="six wide computer twelve wide mobile six wide tablet column column-blocks-wrapper">
                                    {data.image && (<Image src={data.image['@id'] + '/@@images/image/large'} alt={data.alt ? data.alt : null} />)}
                                </div>
                            </div>
                        </>
                    )}
                </Container>

            </div>
        </div >
    );
};

export default HighlightView;
